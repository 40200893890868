import React, { useEffect, useState } from "react";
import { convertToRaw} from "draft-js";
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import draftToHtml from "draftjs-to-html";
import styles from "../../styles.css";

import ParagraphView from "./view";
import { hi } from "date-fns/locale";

const Paragraph = ({
  title,
  id,
  getDescription,
  content,
  type,
  viewPdf,
  highlightItem,
  handleDelete,
  onMouseEnter,
  onMouseLeave,
  pdf
  

}) => {
  //const [editorState, setEditorState] = useState(EditorState.createEmpty());
  
  
  
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(content)
      )
    )
  );

  const [show, setShow] = useState(pdf == true ? true : false);
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    setHighlight(highlightItem);
}, [highlightItem]);

  const onChangeDelete = () => {
    handleDelete(id);
  };
  const onChangeViewPdf = () => {
    setShow(!show);
    viewPdf(show,id);
  };
  const onMouseEnterEvent = () => {
    onMouseEnter(id,true);
    setHighlight(true);
  };
  const onMouseLeaveEvent = () => {
    onMouseLeave(id,false);
    setHighlight(false);
  }
  const onEditorStateChange = (editorState) => {
    if (editorState) {
      getDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())),id);
    }
    setEditorState(editorState);
  };
  return (
    <React.Fragment>
      <div className={highlight == true && 'highlighted'} onMouseEnter={onMouseEnterEvent} onMouseLeave={onMouseLeaveEvent}>
     
      <ParagraphView
       

        title={title}
        type={type}
        content={content}
        viewPdf={show}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        onChangeNotSeeIntoPDF={onChangeViewPdf}
        onChangeDelete={onChangeDelete}
       

      
      />
      </div>
    </React.Fragment>
  );
};

export default Paragraph;
