import React, {useState} from 'react';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import PercentageDocPlace from '../../../../components/form/percentage';
import GroupsIcon from '@mui/icons-material/Groups';

ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


export const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: false,
                },
            },
        ],
    },
};

function ChartInfo(props) {

        if( props.type === "responsivePie") {
            return (
                <div className="row dashboard-card">
                    <div className="col-12">
                        <h3 className="title">
                            {props.title}
                        </h3>
                    </div>
                    <div className="col-12 parent" style={{height: 250}}>

                        <ResponsivePie
                            data={props.data}
                            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            radialLabelsTextColor="#333333"
                            sliceLabelsSkipAngle={10}
                            sliceLabelsTextColor="#333333"
                            enableRadialLabels={false}
                            enableSliceLabels={false}
                            colors={["#009a17", "#006ac6", "#00c4d7"]}
                            borderColor="black"
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: 'color' }}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    size: 4,
                                    padding: 1,
                                    stagger: true
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 60,
                                    itemWidth: 50,
                                    itemHeight: 20,
                                    itemsSpacing: 15,
                                    symbolSize: 10,
                                    itemDirection: 'left-to-right'
                                }
                            ]}
                        >
                        </ResponsivePie>
                    </div>
                </div>
            )
        } else if (props.type === "userDocumentsPie"){
            if(props.data.datasets && props.data.datasets.length >0){
                return (
                    <div className="row dashboard-card">
                        <div className="col-12">
                            <h3 className="title">
                                {props.title}
                            </h3>
                        </div>
                        <div className="col-12 parent" style={{height: 150}}>
                            <Pie data={props.data} />
                        </div>
                    </div>
                )
            }else{
                return null
            }
        }else if( props.type === "responsiveBar") {
            if(props.data.datasets && props.data.datasets.length >0){
                return (
                    <div className="row dashboard-card">
                        <div className="col-12">
                            <h3 className="title">
                                {props.title}
                            </h3>
                        </div>
                        <div className="col-12 parent" style={{height: 350}}>
                            <Bar data={props.data} options={options} />
                        </div>
                    </div>
                )
            }else{
                return null
            }
        } else if( props.type === "storageWaffle") {
            return (
                <div className="row dashboard-card" style={{height: 250}}>
                    <div className="col-12">
                        <h3 className="title text-center">
                            {props.title}
                        </h3>
                    </div>
                    <PercentageDocPlace percentage={props.data} txt={'Consumed of your storage capacity'} />
                </div>
            )
        } else if (props.type === "textInfo") {
            return (
                <div className="row dashboard-card" style={{height: 150}}>
                    <div className="col-12 parent">
                        <p className="chart-text">
                            {props.data.actualUsers}
                        </p>
                    </div>
                    <div className="col-12">
                        <h3 style={{textAling: 'center'}} className="title title-info text-center">
                            <GroupsIcon  style={{fontSize:'50px'}}/>
                            <div style={{marginTop:'-15px'}}>
                                {props.title}
                            </div>
                        </h3>
                    </div>
                </div>
            )
        }

}

export default ChartInfo
