import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {colors} from "../../components/theme";


export const Title = styled.h1`
    color: ${colors.blue};
    margin: -15px 0px 0 0;
    padding: 0;
    font-weight: bold;
`;


export const WrapperDateRange = styled.div`
      width: 400px;
      margin: 25px 0 0 0;
      float: left;
`;

export const WrapperPeriod = styled.div`
      width: 400px;
      margin: 25px 0 0 0;
      float: left;
      margin-right: 25px;
`;


export const useStyles = makeStyles((theme) => ({
    wrapperOverview: {
        display: 'inline-block',
        width: '100%',
        marginTop: '20px'
    }
}));

export const WrapperRefreshButton = styled.div`   
      width: 270px;
      margin: 35px 0px 0px 10px;
      float: left;
`;

  



