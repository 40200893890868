export const authAccess = (body) => {
  let typePlan = [];
  let permissionsConfig = [];

  if (localStorage.getItem("docplace")) {
    typePlan = [];

    if (JSON.parse(localStorage.getItem("docplace")).plan) {
      typePlan.push(JSON.parse(localStorage.getItem("docplace")).plan);
    }

    typePlan.map((v) => {
      switch (v.id) {
        case "6": // code 6: Business Essential & eSignature
          permissionsConfig.push({
            name: "dashboard",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "explorer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "search",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "inputMethod",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "documentType",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "masterTemplate",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "docViewer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "documentType/detail",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "rol",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "user",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "stampSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "placeSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "signature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "esignatureRegister",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "eSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormsInbox",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormsTemplate",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormViewer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Templates",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Assigned Forms",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Trash-Bin",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "forms-components",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "CompletedForm",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          break;
        case "1": // code 1: Business Essential
          permissionsConfig.push({
            name: "dashboard",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "explorer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "search",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "inputMethod",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "documentType",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "masterTemplate",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "docViewer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "documentType/detail",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "rol",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "user",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "stampSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "placeSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "signature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "esignatureRegister",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "eSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormsInbox",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormsTemplate",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormViewer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Templates",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Assigned Forms",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Trash-Bin",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "forms-components",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "CompletedForm",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          break;
        case "7": // code 7: eSignature
          permissionsConfig.push({
            name: "eSignatureE",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "eSignatureInbox",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "DashboardSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "stampSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "docViewer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "placeSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "esignatureRegister",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormsInbox",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormsTemplate",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Templates",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Assigned Forms",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Trash-Bin",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "forms-components",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "CompletedForm",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          break;
        case "8": // case 8: Free Trial eSignature
          permissionsConfig.push({
            name: "trial",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "eSignatureE",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "eSignatureInbox",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "docViewer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "stampSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "placeSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "esignatureRegister",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormsInbox",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormsTemplate",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormViewer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Templates",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Assigned Forms",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Trash-Bin",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "forms-components",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "CompletedForm",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          break;
        case "2": // code 2: Enterprise
          permissionsConfig.push({
            name: "dashboard",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "explorer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "search",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "inputMethod",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "documentType",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "masterTemplate",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "docViewer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "documentType/detail",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "rol",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "user",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "stampSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "placeSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "signature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "esignatureRegister",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "eSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormsInbox",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormsTemplate",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormViewer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Templates",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Assigned Forms",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Trash-Bin",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "forms-components",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "CompletedForm",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          break;
        default:
          permissionsConfig.push({
            name: "dashboard",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "explorer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "search",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "inputMethod",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "documentType",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "masterTemplate",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "docViewer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "documentType/detail",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "rol",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "user",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "stampSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "placeSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "signature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "esignatureRegister",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });

          permissionsConfig.push({
            name: "eSignature",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormsInbox",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormsTemplate",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "FormViewer",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Templates",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Assigned Forms",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "Trash-Bin",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          permissionsConfig.push({
            name: "CompletedForm",
            actions: {
              read: true,
              write: true,
              delete: true,
            },
          });
          break;
      }
    });

    if (JSON.parse(localStorage.getItem("docplace")).superUser === 1) {
      permissionsConfig.push({
        name: "superUser",
        actions: {
          read: true,
          write: true,
          delete: true,
        },
      });
    }
  }

  return {
    authAccess: permissionsConfig,
  };
};

export const findPage = (page) => {
  const pages = authAccess().authAccess;
  return pages.find((i) => i.name === page) ? true : false;
};
