import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';

import { Container } from "reactstrap";
import { Title, WrapperDateRange, WrapperPeriod, useStyles } from "./style";
import SelectDropdown from "../../components/form/selectDropdown";
import LoadingComponent from "../../components/loading";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "../../store/user/actions";
import { getDocTypeList } from "../../store/docType/actions";
import { getReviewalList, getDocPercentageList, getDocSharedList, getDocPDList } from "../../store/dashboard/actions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './dashboard-style.scss';
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import OverviewInfo from './components/overviewInfo/overview-info';
import Grid from '@mui/material/Grid';
import ChartInfo from './components/chartInfo/chart-info';
import DateRangePickerDocPlace from '../../components/form/dateRangePickerDocPlace';

const configBarPeriod = {
    indexBy: "month",
    layout: "vertical",
    groupMode: "stacked",
    keys: [],
    colors: ["#009a17", "#006ac6", "#00c4d7"],
    margin: { top: 20, right: 10, bottom: 70, left: 30 }
}

const storageData = [
    {
        "id": "storage",
        "label": "Storage",
        "value": 89.7,
        "color": "#006ac6"
    }
]

const DashBoardDocPlace = (props) => {
    const notify = (msj, event) => toast[event](msj);
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [startPage] = useState(true);
    const [period, setPeriod] = useState({ value: { value: 'MONTH', label: 'Monthly' }, error: false });
    const [showDetail, setShowDetail] = useState({ type: '', show: '' });
    const [pieDataFull, setPieDataFull] = useState([]);
    const [barDataFull, setBarDataFull] = useState([]);
    const [isCustom, setCustom] = useState(false);
    const [dateRange, setDateRange] = useState({ value: [null, null], error: false, notReady: false });
    const [infoData, setInfoData] = useState({
        totalUsers: 0,
        actualUsers: 0
    });

    const [listPeriod] = useState([
        { value: 'DAY', label: 'Today' },
        { value: 'WEEK', label: 'This week' },
        { value: 'MONTH', label: 'This month' },
        { value: 'YEAR', label: 'This year' },
        { value: 'CUSTOM', label: 'Custom' } //DOC-1604 add custom as an option
    ]);

    const { responseUser, errorUser, loading } = useSelector(state => ({
        responseUser: state.User.responseUser,
        errorUser: state.User.errorUser,
        loading: state.User.loadingUser,
    }))

    const { responseDocType, errorDocType, loading2 } = useSelector(state => ({
        responseDocType: state.DocType.responseDocType,
        errorDocType: state.DocType.errorDocType,
        loading2: state.DocType.loadingDocType,
    }))

    const { responseReviewal, errorReviewal, loading3 } = useSelector(state => ({
        responseReviewal: state.Dashboard.responseReviewal,
        errorReviewal: state.Dashboard.errorReviewal,
        loading3: state.Dashboard.loadingReviewal,
    }))

    const { responseDocPD, errorDocPD, loading4 } = useSelector(state => ({
        responseDocPD: state.Dashboard.responseDocPD,
        errorDocPD: state.Dashboard.errorDocPD,
        loading4: state.Dashboard.loadingDocPD,
    }))

    const { responseDocPercentage, errorDocPercentage, loading5 } = useSelector(state => ({
        responseDocPercentage: state.Dashboard.responseDocPercentage,
        errorDocPercentage: state.Dashboard.errorDocPercentage,
        loading5: state.Dashboard.loadingDocPercentage,
    }))



    useEffect(() => {
        dispatch(getUserList({}, navigate))
    }, [startPage])


    useEffect(() => {
        if (errorUser) {
            notify(errorUser?.response?.data?.error?.messages[0].message, 'error');
        }
    }, [errorUser])

    useEffect(() => {
        if (errorDocType) {
            if (errorDocType.response.status) {
                if (errorDocType.response.status === 401) {
                }
            }
            notify(errorDocType?.response?.data?.error?.messages[0].message, 'error');
        }
    }, [errorDocType])

    useEffect(() => {
        if (period.value.value !== "CUSTOM") {   //DOC-1604 if it is custom don't make a refresh
            setCustom(false);  //set custom as false to hide the datepickers
            dispatch(getDocTypeList({ from: '', to: '', period: period.value.value }, navigate))
            dispatch(getReviewalList({ from: '', to: '', period: period.value.value }, navigate))
            dispatch(getDocPercentageList({ from: '', to: '', period: period.value.value }, navigate))
            dispatch(getDocSharedList({ from: '', to: '', period: period.value.value }, navigate))
            dispatch(getDocPDList({ from: '', to: '', period: period.value.value }, navigate))
        }
        else {  //DOC-1604 if it is custom then make visible the data range
            setCustom(true);
        }
    }, [period])

    useEffect(() => {
        if (responseUser && responseUser.header.totalRecords > 0) {
            setInfoData({
                totalUsers: responseUser.header.totalRecords,
                actualUsers: responseUser.header.totalRecords
            });
        }
    }, [responseUser])




    useEffect(() => {
        if (responseDocPD && responseDocPD.data.total > 0) {
            let pieData = [];
            let pieColumns = [];

            responseDocPD.data.documentTypePercentage.map((v) => {
                pieColumns.push(v.document);
                pieData.push(v.percentage);
            });

            let pieDataFullv = {
                labels: pieColumns,
                datasets: [
                    {
                        label: '# of Votes',
                        data: pieData,
                        backgroundColor: [
                            '#006ac6',
                            '#00cdd7',
                            'rgb(0, 154, 23)',
                            '#ffba57',
                            '#343a40',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            '#006ac6',
                            '#00cdd7',
                            'rgb(0, 154, 23)',
                            '#ffba57',
                            '#343a40',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderWidth: 1,
                    },
                ],
            };
            setPieDataFull(pieDataFullv);
        }
    }, [responseDocPD])

    useEffect(() => {
        if (responseDocPercentage && responseDocPercentage.header.totalRecords > 0) {
            let barData = [];
            let barDataColumns = [];
            let barDataSet = [];
            let objectSize = Object.keys(responseDocPercentage.data).length;

            responseDocPercentage.data.map((v) => {
                barDataColumns.push(v.month);
                let keys = Object.keys(v.values);

                keys.map((k) => {
                    barData.push(v.values[k])
                });

                barDataSet.push({
                    label: v.month,
                    data: barData,
                    backgroundColor: '#006ac6',
                    stack: 'Stack 0',
                })

                barData = [];

            });

            setBarDataFull({
                labels: barDataColumns,
                datasets: barDataSet,
            });

        }
    }, [responseDocPercentage])

    const onChangeRange = () => { //DOC-1604 / DOC-1754 
        let date1 = dateRange.value[0];
        let date2 = dateRange.value[1];
        let isValid = (!dateRange.error) && (date1 && date2) && (date1.toString() !== "Invalid Date" && date2.toString() !== "Invalid Date");  //avoid invalids and wrong range
        if (isValid) refreshDashboard();
    }

    const handleVisibility = (value, view) => {
        setShowDetail({
            type: value,
            show: view
        });
    }

    /**DOC 1754 sepparate the functionality to execute the data fill for all the dashboard*/
    const refreshDashboard = () => {
        if (dateRange.value[0] && dateRange.value[1]) {
            let dateStart = moment.tz(dateRange.value[0], 'UTC').format('MM/DD/yyyy');
            let dateEnd = moment.tz(dateRange.value[1], 'UTC').format('MM/DD/yyyy');
            dispatch(getDocSharedList({  //get the number of document shared
                from: dateStart,
                to: dateEnd,
                period: ''
            }, navigate));
            dispatch(getDocTypeList({   //fills the number of document templates
                from: dateStart,
                to: dateEnd,
                period: ''
            }, navigate));
            dispatch(getReviewalList({  //fills the number of documents in reviewal
                from: dateStart,
                to: dateEnd,
                period: ''
            }, navigate));
            dispatch(getDocPercentageList({  //fills the pie chart by percentage 
                from: dateStart,
                to: dateEnd,
                period: ''
            }, navigate));

            dispatch(getDocPDList({  //get the number of documents per periods, bar chart
                from: dateStart,
                to: dateEnd,
                period: ''
            }, navigate));
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>DocPlace Dashboard</title>
                </MetaTags>
                <Container fluid>
                    <Title >Dashboard</Title>
                    <ToastContainer autoClose={3000} />
                    <LoadingComponent show={loading || loading2} text={"Getting Dashboard..."} />
                    <WrapperPeriod>
                        <SelectDropdown
                            id={'period'}
                            field={period}
                            arrayList={listPeriod}
                            defaultList={[{ value: 'MONTH', label: 'This month' }]}
                            setField={setPeriod}
                            label="Period"
                            helperText="Choose a period to search the corresponding data"
                        />
                    </WrapperPeriod>
                    {isCustom &&
                        <WrapperDateRange>
                            <DateRangePickerDocPlace
                                id={'dateSearch'}
                                field={dateRange}
                                setField={setDateRange}
                                required={true}
                                startDateText="Start Date"
                                endDateText="End Date"
                                helperText="Choose a date range to search the corresponding data"
                                execute={onChangeRange}
                            />
                        </WrapperDateRange>
                    }
                    <Grid container spacing={2}>
                        <Grid item xs={3} md={3}>
                            <OverviewInfo numberValue={0} textValue="Files Shared" icon="shared" type="shared" selectDetail={handleVisibility.bind(this)} ></OverviewInfo>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <OverviewInfo numberValue={responseDocType ? responseDocType.header.totalRecords : 0} textValue="Document Templates" icon="doctype" type="templates" selectDetail={handleVisibility.bind(this)} ></OverviewInfo>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <OverviewInfo numberValue={responseDocPD ? responseDocPD.data.total : 0} textValue="Uploaded Documents" icon="document" type="templates" selectDetail={handleVisibility.bind(this)} ></OverviewInfo>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <OverviewInfo numberValue={responseReviewal ? responseReviewal.header.totalRecords : 0} textValue="Files in Reviewal" icon="document" type="templates" selectDetail={handleVisibility.bind(this)} ></OverviewInfo>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4} md={4}>
                            <ChartInfo title="Users on documents" data={pieDataFull} type="userDocumentsPie"></ChartInfo>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <ChartInfo title="Documents per period" data={barDataFull} type="responsiveBar" config={configBarPeriod}></ChartInfo>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <ChartInfo title="Storage in DocPlace" data={45} type="storageWaffle"></ChartInfo>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <ChartInfo title="Authors" data={infoData} type="textInfo"></ChartInfo>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default DashBoardDocPlace;
