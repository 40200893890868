import React from "react";
import {Percentage, PercentageTxt, PercentageTxtinfo, useStyles, WrapperPercentage} from './style'

const PercentageDocPlace = ({ percentage, txt }) => {
    const classes = useStyles();

    return (
        <>
            <PercentageTxt>
                {percentage+'%'}
                <PercentageTxtinfo> {txt} </PercentageTxtinfo>
            </PercentageTxt>
            <WrapperPercentage>
                <Percentage style={{width:percentage+'%'}}></Percentage>
            </WrapperPercentage>
        </>

    )
}


export default PercentageDocPlace

