import React from "react";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  ButtonContainer,
  ButtonContainerTools,
  Container,
  ContainerTextField,
  DivHelperTextTitle,
  useStyles,
  WrapperTextShort,
  Title
} from "./style";
import { colors } from "../../../../components/theme";


const ShortTextViewDocPlace = (props) => {
  const classes = useStyles();
  return (
    <WrapperTextShort>
    
        <>
          <Container>
            <Title>{props.title}</Title>
            <ButtonContainer>
              {props.viewPdf ? (
              <Button
                variant="text"
                onClick={props.onChangeViewPdf}
                style={{
                  textTransform: "none",
                  margin: "-12px 0 20px 0",
                  backgroundColor: props.notSeeIntoPDF && colors.gray3,
                }}
                startIcon={<VisibilityOffIcon />}
              >
                Hide in PDF
              </Button>
              ) : (
              <Button
                variant="text"
                onClick={props.onChangeViewPdf}
                style={{
                  textTransform: "none",
                  margin: "-12px 0 20px 0",
                  backgroundColor: props.notSeeIntoPDF && colors.gray3,
                }}
                startIcon={<VisibilityIcon />}
              >
                Show in PDF
              </Button>
              )}
            </ButtonContainer>
            <ContainerTextField>
              <TextField
                id="helpTextTitle"
                placeholder="Click to Enter Title"
                fullWidth={true}
                variant="standard"
                value={props._title}
                onChange={props.onChangeTitle}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                  },
                }}
              />
            </ContainerTextField>
            <TextField
              id="shortText"
              label={props.label}
              disabled={props.disabled}
              variant="outlined"
              fullWidth={true}
              required={true}
              onChange={props.onChangeShortText}
              InputProps={{}}
            />
            <ContainerTextField>
              <TextField
                id="helpTestDescription"
                placeholder="Click to Enter Description"
                fullWidth={true}
                value={props._description}
                variant="standard"
                onChange={props.onChangeDescription}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "12px",
                    fontWeight: "lighter",
                  },
                }}
              />
            </ContainerTextField>
            <ButtonContainerTools>
          
              <Button
                onClick={props.onChangeDelete}
                style={{
                  textTransform: "none",
                }}
                color="error"
                variant={"text"}
                startIcon={<DeleteForeverIcon />}
              >
                Delete
              </Button>
            </ButtonContainerTools>
          </Container>
        </>
      
    </WrapperTextShort>
  );
};
export default ShortTextViewDocPlace;
