import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import DocumentsSentViewDocPlace from "./view";
import TitleDocPlace from "../../components/title/title";
import SearchDocPlace from "../../components/search/search";
import { WrapperSignature } from "./style";
import DataGridDemo from "./resource/grid/grid";
import {
  getRequiredSignatureDocument,
  getValidateUserSignature,
  validateUserSignatureClearData,
} from "../../store/eSignature/actions";
import LoadingComponent from "../../components/loading";

const DocumentSignatureDocPlace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userSignature, setUserSignature] = useState(true);
  const [userRequiredSignature, setUserRequiredSignature] = useState(true);
  const [documentName, setDocumentName] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { responseValidateUserSignature, loadingValidateUserSignature } =
    useSelector((state) => ({
      responseValidateUserSignature:
        state.UserValidateSignature.responseValidateUserSignature,
      loadingValidateUserSignature:
        state.UserValidateSignature.loadingValidateUserSignature,
    }));

  const {
    responseRequiredSignatureDocument,
    loadingRequiredSignatureDocument,
  } = useSelector((state) => ({
    responseRequiredSignatureDocument:
      state.UserValidateSignature.responseRequiredSignatureDocument,
    loadingRequiredSignatureDocument:
      state.UserValidateSignature.loadingRequiredSignatureDocument,
  }));

  const findDocumentTypes = (term) => {
    setUserRequiredSignature(
      term
        ? documentName.filter((doc) =>
            doc.document_name.toLowerCase().includes(term)
          )
        : documentName
    );
  };

  const callModalConfirmation = () => {
    setShowConfirmation(!showConfirmation);
  };

  const searchFile = (filterSearch) => {
    findDocumentTypes(filterSearch);
  };

  useEffect(() => {
    if (responseValidateUserSignature) {
      setUserSignature(responseValidateUserSignature.data.isValid);
      dispatch(validateUserSignatureClearData());
    }
  }, [responseValidateUserSignature]);

  useEffect(() => {
    let newObject = [];
    if (responseRequiredSignatureDocument) {
      responseRequiredSignatureDocument?.data?.map((signatureDocument, i) => {
        newObject.push({ ...signatureDocument, id: i });
      });
      setUserRequiredSignature(newObject);
      setDocumentName(newObject);
    }
  }, [responseRequiredSignatureDocument]);

  useEffect(() => {
    if (!responseValidateUserSignature) {
      dispatch(getValidateUserSignature());
    }
  }, []);

  useEffect(() => {
    if (!responseRequiredSignatureDocument) {
      dispatch(getRequiredSignatureDocument());
    }
  }, []);

  useEffect(() => {
    if (!userSignature) {
      setShowConfirmation(true);
    }
  }, [userSignature]);

  return (
    <React.Fragment>
      <DocumentsSentViewDocPlace
        show={showConfirmation}
        close={callModalConfirmation}
      />
      <Container fluid>
        <TitleDocPlace title={"Documents for signature"} viewActive={false} />
        <LoadingComponent
          show={loadingRequiredSignatureDocument}
          text={"Obtaining documents for signature..."}
        />
        <SearchDocPlace callSearch={searchFile.bind(this)} />
        <WrapperSignature>
          <DataGridDemo rows={userRequiredSignature} />
        </WrapperSignature>
      </Container>
    </React.Fragment>
  );
};

export default DocumentSignatureDocPlace;
