import React from "react";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  ButtonContainer,
  ButtonContainerTools,
  Container,
  ContainerTextField,
  SelectTitle,
  useStyles,
  WrapperSelectList,
  Title
} from "./style";
import "../../../../App.css";
import { colors } from "../../../../components/theme";
 
 
const SelectListFormsView = (props) => {
  const classes = useStyles();
  return (
    <WrapperSelectList>
 
        <Container>
          <Title>{props.title}</Title>
          <ButtonContainer>
            {props.viewPdf ? (
            <Button
              variant="text"
              onClick={props.onChangeViewPdf}
              style={{
                textTransform: "none",
                margin: "-12px 0 20px 0",
                backgroundColor: props.notSeeIntoPdfSelectRes && colors.gray3,
              }}
              startIcon={<VisibilityOffIcon />}
            >
              Hide in PDF
            </Button>
             ) : (
            <Button
              variant="text"
              onClick={props.onChangeViewPdf}
              style={{
                textTransform: "none",
                margin: "-12px 0 20px 0",
                backgroundColor: props.notSeeIntoPdfSelectRes && colors.gray3,
              }}
              startIcon={<VisibilityIcon />}
            >
              Show in PDF
            </Button>
            )}
          </ButtonContainer>
          <ContainerTextField>
            <TextField
              placeholder="Click to Enter Title"
              fullWidth={true}
              variant="standard"
              value={props._title}
              onChange={props.onChangeTitle}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: "12px",
                  fontWeight: "bold",
                },
              }}
            />
          </ContainerTextField>
          <FormControl style={{ width: "100%" }}>
            <InputLabel>{props.text}</InputLabel>
            <Select
              disabled={props.disable}
              variant={props.variant}
              defaultValue={props.defaultValue}
              // onChange={props.onChange}
              label={"Select the option"}
              value={props.options[0]?.value}
              input={<OutlinedInput label={props.text} />}
              >
                {props.options?.map((value) => (
                  <MenuItem key={value.order} value={value.value}>
                    <ListItemText primary={value.label} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <ContainerTextField>
            <TextField
              placeholder="Click to Enter Description"
              fullWidth={true}
              variant="standard"
              value={props._description}
              onChange={props.onChangeDescription}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: "12px",
                  fontWeight: "lighter",
                },
              }}
            />
          </ContainerTextField>
          <ButtonContainerTools>
          <Button
              onClick={props.onChangeSettings}
              style={{
                textTransform: "none",
                marginRight: "20px",
              }}
              variant={"text"}
              startIcon={<SettingsSuggestIcon />}
            >
              Settings
            </Button>
            <Button
              onClick={props.onChangeDelete}
              style={{
                textTransform: "none",
              }}
              color="error"
              variant={"text"}
              startIcon={<DeleteForeverIcon />}
            >
              Delete
            </Button>
          </ButtonContainerTools>
        </Container>
      
    </WrapperSelectList>
  );
};

export default SelectListFormsView;
