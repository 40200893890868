import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import { Container } from "reactstrap";
import SearchDocPlace from "../../components/search/search";
import NavDocPlace from "./components/nav/nav";
import FileDocPlace from "./components/files/files";
import { toast, ToastContainer } from "react-toastify";
//API CALL
import { getUserList } from "../../store/user/actions";
import { getDocTypeList1 } from "../../store/docType/actions";
import { getTreeList } from "../../store/tree/actions";
import { postDeleteDoc, postEmptyTrashBin } from "../../store/explorer/actions";
import {
  getDetailFolderList,
  getSearchArchivesList,
} from "../../store/document/actions";
//END
import "./explorer-style.scss";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../components/title/title";
import SelectDropdown from "../../components/form/selectDropdown";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../components/loading";
import Button from "@mui/material/Button";
import UploadIcon from "@mui/icons-material/Upload";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { TitleDocType, useStyles } from "./style";
import {
  uploadFileManual,
  validateFiles,
} from "../../services/FileUploadService";
import { getCanonicalPath } from "../../services/ocr";
import Swal from "sweetalert2";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import document from "../../assets/document.svg";
import Grid from "@mui/material/Grid";

var filterSearch;
var bySearch = false;

const ExplorerDocPlace = (props) => {
  let params = useParams();
  const notify = (msj, event) => toast[event](msj);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startPage] = useState(true);
  const [mapPath, setMapPath] = useState([]);
  const [showTree, setShowTree] = useState(true);
  const [loading7, setLoading7] = useState(false);
  const [archiveId, setArchiveId] = useState("");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [tree, setTree] = useState({
    label: "",
    value: "",
    parent: "",
  });
  const [files, setFiles] = useState([]);
  const [makeTree, setMakeTree] = useState([]);
  const [nextPage, setNextPage] = useState([]);
  const [doctypes, setDoctypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [parentArchiveId, setParentArchiveId] = useState("");
  const [detailFolder, setDetailFolder] = useState([]);
  const [totalFiles, setTotalFiles] = React.useState([]);
  const [closeUploadFiles, setCloseUploadFiles] = React.useState(false);
  const [upDocType, setUpDocType] = React.useState({});
  const [listUpDocType, setListUpDocType] = React.useState([]);
  const [loading8, setLoading8] = React.useState(false);
  const [item, setItem] = React.useState(null);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [showOptionMenuItems, setShowOptionMenuItems] = React.useState(false);
  const [isTrash, setIsTrash] = React.useState(false);
  const [messageText, setMessageText] = React.useState("Getting Explorer Data");
  const [resetPager, setResetPager] = React.useState(false);
  const [documentName, setDocumentName] = React.useState("");

  const [uploaded, setUploaded] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const handleClose = () => setOpenModal(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const { responseUser, errorUser, loading } = useSelector((state) => ({
    responseUser: state.User.responseUser,
    errorUser: state.User.errorUser,
    loading: state.User.loadingUser,
  }));
  const { responseDocType1, errorDocType1, loading2 } = useSelector(
    (state) => ({
      responseDocType1: state.DocType.responseDocType1,
      errorDocType1: state.DocType.errorDocType1,
      loading2: state.DocType.loadingDocType1,
    })
  );

  const { responseTree, errorTree, loading3 } = useSelector((state) => ({
    responseTree: state.Tree.responseTree,
    errorTree: state.Tree.errorTree,
    loading3: state.Tree.loadingTree,
  }));
  const { responseDetailFolder, errorDetailFolder, loading4 } = useSelector(
    (state) => ({
      responseDetailFolder: state.DetailFolder.responseDetailFolder,
      errorDetailFolder: state.DetailFolder.errorDetailFolder,
      loading4: state.DetailFolder.loadingDetailFolder,
    })
  );

  const { responseSearchArchives, errorSearchArchives, loading5 } = useSelector(
    (state) => ({
      responseSearchArchives: state.DetailFolder.responseSearchArchives,
      errorSearchArchives: state.DetailFolder.errorSearchArchives,
      loading5: state.DetailFolder.loadingSearchArchives,
    })
  );
  const { responseDeleteDoc, errorDeleteDoc, loading6 } = useSelector(
    (state) => ({
      responseDeleteDoc: state.Explorer.responseDeleteDoc,
      errorDeleteDoc: state.Explorer.errorDeleteDoc,
      loading6: state.Explorer.loadingDeleteDoc,
    })
  );

  /**DOC-1441  call to EmptytrashBin endpoint*/
  const { responseEmptyTrash, errorEmptyTrash, loadingEmptyTrash } =
    useSelector((state) => ({
      responseEmptyTrash: state.Explorer.responseEmptyTrash,
      errorEmptyTrash: state.Explorer.errorEmptyTrash,
      loadingEmptyTrash: state.Explorer.loadingEmptyTrash,
    }));

  useEffect(() => {
    setPage(1);
    setResetPager(true);
    refresh("", page, pageSize);
  }, [responseEmptyTrash]);
  //

  useEffect(() => {
    if (params.id) {
      setLoading8(true);
      setTimeout(() => {
        setLoading8(false);
        setArchiveId(params.id);
        getCanonicalPathFn(params.id);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (responseDocType1 && responseDocType1.data) {
      let v = [];
      responseDocType1.data.map((item) => {
        v.push({
          label: item.name,
          value: item.id,
        });
      });
      setListUpDocType(v);
    }
  }, [responseDocType1]);

  useEffect(() => {
    if (responseDeleteDoc !== null) {
      dispatch(
        getDetailFolderList(
          {
            parentArchiveId:
              mapPath.length > 0 ? mapPath[mapPath.length - 1].id : "",
            page: page,
            pageSize: pageSize,
          },
          navigate
        )
      );
      dispatch(
        getTreeList(
          {
            parentArchiveId:
              mapPath.length > 0 ? mapPath[mapPath.length - 1].id : "",
            direction: "",
          },
          navigate
        )
      );
      //setPage(1); setResetPager(true);
    }
  }, [responseDeleteDoc]);
  useEffect(() => {
    if (!params.id) {
      dispatch(getUserList({}, navigate));
      dispatch(
        getDocTypeList1(
          {
            page: page,
            pageSize: pageSize,
            sortBy: "name",
            sortOrder: 1,
            filtered: true,
          },
          navigate
        )
      );
      dispatch(
        getTreeList(
          { parentArchiveId: parentArchiveId, direction: "" },
          navigate
        )
      );
      dispatch(
        getDetailFolderList(
          {
            parentArchiveId: "",
            page: 1,
            pageSize: pageSize,
          },
          navigate
        )
      );
    }
  }, [startPage]);

  useEffect(() => {
    if (responseSearchArchives) {
      setDetailFolder(responseSearchArchives);
    }
  }, [responseSearchArchives]);

  useEffect(() => {
    if (responseDetailFolder) {
      setDetailFolder(responseDetailFolder);
    }
  }, [responseDetailFolder]);

  const searchFile = (_filterSearch) => {
    setIsTrash(false);
    setPage(1);
    setResetPager(true);
    if (_filterSearch) {
      bySearch = true;
      filterSearch = _filterSearch;
      let id = mapPath.length > 0 ? mapPath[mapPath.length - 1].id : "";
      let obTerm = {
        filters: [],
        fullTextSearch: { text: _filterSearch, fields: [] },
      };

      dispatch(
        getSearchArchivesList(
          {
            parentArchiveId: id ? id : "",
            page: 1,
            pageSize: pageSize,
            obTerm,
          },
          navigate
        )
      );
    } else {
      bySearch = false;
      refresh("", page, pageSize);
    }
  };

  const getCanonicalPathFn = (id) => {
    getCanonicalPath(id, (event) => {})
      .then((response) => {
        if (response.data.length > 0) {
          let listMP = [];
          let responseSorted = response.data.sort((a, b) => {
            if (a.Level > b.Level) {
              return 1;
            }
            if (a.Level < b.Level) {
              return -1;
            }
            return 0;
          });

          responseSorted.map((cp) => {
            if (cp.Type === "Folder") {
              listMP.push({
                name: cp.Name,
                id: cp.Id,
                parent: cp.ParentArchiveId,
              });
            }
          });
          clickMap(listMP[listMP.length - 1].id);
          setMapPath(listMP);
          setPage(1);
          setResetPager(true);
        }
      })
      .catch((err) => {
        //setLoading1(false);
      })
      .finally(() => {
        //setLoading(false);
      });
  };

  const showTreeFN = (item) => {
    setShowTree(item);
    setPage(1);
    setResetPager(true);
  };

  const refresh = (item, mpage = page, mrows = pageSize) => {
    // if(item){
    dispatch(
      getDetailFolderList(
        {
          parentArchiveId:
            mapPath.length > 0 ? mapPath[mapPath.length - 1].id : "",
          page: mpage,
          pageSize: mrows,
        },
        navigate
      )
    );
    dispatch(
      getTreeList(
        {
          parentArchiveId:
            mapPath.length > 0 ? mapPath[mapPath.length - 1].id : "",
          direction: "",
        },
        navigate
      )
    );
    setPage(mpage);
    setPageSize(mrows);
    //}
  };
  const refreshSearch = (item, mpage = page, mrows = pageSize) => {
    let _filterSearch = filterSearch;
    let id = mapPath.length > 0 ? mapPath[mapPath.length - 1].id : "";
    let obTerm = {
      filters: [],
      fullTextSearch: { text: _filterSearch, fields: [] },
    };

    dispatch(
      getSearchArchivesList(
        {
          parentArchiveId: id ? id : "",
          page: mpage,
          pageSize: mrows,
          obTerm,
        },
        navigate
      )
    );
    setPage(mpage);
    setPageSize(mrows);
  };

  const upLevel = (item) => {
    setIsTrash(false);
    let m = mapPath;
    if (m.length - 1 >= 1) {
      //this.props.fetchDocumentsDetails(m[(m.length - 2)].id,null, 1, pageSize);
      m.splice(m.length - 1, 1);
    } else {
      //this.props.getFolders();
      m = [];
    }
    this.setState({
      mapPath: m,
      page: 1,
    });
    setPage(1);
  };

  const homeTree = (item) => {
    setIsTrash(false);
    dispatch(
      getDetailFolderList(
        { parentArchiveId: "", page: 1, pageSize: pageSize },
        navigate
      )
    );
    dispatch(getTreeList({ parentArchiveId: "", direction: "UP" }, navigate));
    setMapPath([]);
    setPage(1);
    setResetPager(true);
  };

  const clickMap = (id) => {
    dispatch(
      getDetailFolderList(
        {
          parentArchiveId: id ? id : "",
          page: 1,
          pageSize: pageSize,
        },
        navigate
      )
    );
    dispatch(
      getTreeList({ parentArchiveId: id ? id : "", direction: "" }, navigate)
    );
    let newMap = [];
    let end = false;
    mapPath.map((map, idx) => {
      if (map.id != id) {
        if (!end) {
          newMap.push(map);
        }
      } else {
        newMap.push(map);
        end = true;
      }
    });

    setMapPath(newMap);
    setPage(1);
    setResetPager(true);
  };

  const newFolder = (item) => {
    dispatch(
      getDetailFolderList(
        {
          parentArchiveId:
            mapPath.length > 0 ? mapPath[mapPath.length - 1].id : "",
          page: 1,
          pageSize: pageSize,
        },
        navigate
      )
    );
    dispatch(
      getTreeList(
        {
          parentArchiveId:
            mapPath.length > 0 ? mapPath[mapPath.length - 1].id : "",
          direction: "",
        },
        navigate
      )
    );
    setPage(1);
    setResetPager(true);
  };

  const clickTree = (item) => {
    setPage(1);
    setTree({
      label: item.label,
      value: item.value,
      parent: !tree.label ? "" : item.parent ? item.parent.id : "",
    });
    dispatch(
      getTreeList({ parentArchiveId: item.value, direction: "" }, navigate)
    );
    dispatch(
      getDetailFolderList(
        //{ parentArchiveId: item.value, page: page, pageSize: pageSize },
        { parentArchiveId: item.value, page: 1, pageSize: 50 },

        navigate
      )
    );

    let m = mapPath;
    m.push({
      name: item.label,
      id: item.value,
      parent: !tree.label ? "" : item.parent ? item.parent.id : "",
    });
    setMapPath(m);
    setPage(1);
    setResetPager(true);
  };

  const backTree = (item) => {
    setIsTrash(false);
    let newMap = [];
    let end = mapPath.length;
    let origin = mapPath[mapPath.length - 1].parent;
    dispatch(
      getTreeList({ parentArchiveId: item.value, direction: "UP" }, navigate)
    );
    if (!item.value) {
      setTree([]);
    }

    mapPath.map((map, idx) => {
      if (map.id != mapPath[end - 1].id) {
        newMap.push(map);
      }
    });
    dispatch(
      getDetailFolderList(
        {
          parentArchiveId:
            newMap.length > 0 ? newMap[newMap.length - 1].id : origin,
          page: 1,
          pageSize: pageSize,
        },
        navigate
      )
    );

    setMapPath(newMap);
    setPage(1);
    setResetPager(true);
  };

  const clickFile = (item) => {
    let exist = false;
    mapPath.map((map, idx) => {
      if (
        (item.folder && map.name === item.name) ||
        (!item.folder && map.name === item.archive.name)
      ) {
        exist = true;
      }
    });

    if (!exist) {
      let m = mapPath;
      m.push({
        name: item.folder ? item.name : item.archive.name,
        id: item.folder ? item.id : item.archive.id,
      });
      setMapPath(m);
    }
  };

  const addComment = (item) => {
    refresh("", page, pageSize);
  };

  const updateFile = (files) => {
    setCloseUploadFiles(false);
    setTotalFiles(files);
  };

  const moveFile = () => {
    refresh("", page, pageSize);
    //setPage(1); setResetPager(true);
  };

  const reprocessFile = (v) => {
    refresh("", page, pageSize);
    //setPage(1); setResetPager(true);
  };

  const deleteFile = (item) => {
    let id = [];
    //setPage(1); setResetPager(true);
    id.push(item.item.folder ? item.item.id : item.item.fkArchive.id);
    dispatch(postDeleteDoc(id, navigate));
  };

  const shareFile = (item) => {
    // this.props.shareDocument(item.item.id, item.shareFiles);
  };

  const renameFile = () => {
    dispatch(
      getDetailFolderList(
        {
          parentArchiveId:
            mapPath.length > 0 ? mapPath[mapPath.length - 1].id : "",
          page: 1,
          pageSize: pageSize,
        },
        navigate
      )
    );
    dispatch(
      getTreeList(
        {
          parentArchiveId:
            mapPath.length > 0 ? mapPath[mapPath.length - 1].id : "",
          direction: "",
        },
        navigate
      )
    );
    setPage(1);
    setResetPager(true);
  };
  const recoverFile = () => {
    dispatch(
      getDetailFolderList(
        {
          parentArchiveId:
            mapPath.length > 0 ? mapPath[mapPath.length - 1].id : "",
          page: 1,
          pageSize: pageSize,
        },
        navigate
      )
    );

    setPage(1);
    setResetPager(true);
  };
  const openFolder = (item) => {
    setPage(1);
    setResetPager(true);
    // this.props.fetchDocumentsDetails(item.folder ? item.id : item.archive.id, null, page, pageSize);
  };

  const openFile = (item) => {
    setPage(1);
    setResetPager(true);
    navigate("/docViewer/" + item.id); //send current mapPath
  };

  const nextPageFN = (pages, rows) => {
    setPage(pages);
    setPageSize(rows);
    setNextPage(true);

    if (bySearch == true) {
      refreshSearch("", pages + 1, rows);
    } else {
      refresh("", pages + 1, rows);
    }
    /*
    dispatch(
        getDetailFolderList(
            {
              parentArchiveId:
                  mapPath.length > 0 ? mapPath[mapPath.length - 1].id : "",
              page: page,
              pageSize: pageSize,
            },
            navigate
        )
    );

    let m = mapPath;
    if (m.length - 1 >= 0) {
      //this.props.fetchDocumentsDetails(m[(m.length - 1)].id,null, page, pageSize);
    }
    */
  };

  const closeUpload = () => {
    setCloseUploadFiles(true);
    setTotalFiles([]);
  };

  const saveUpload = () => {
    const validateFilePromises = totalFiles.map((file) => {
      setDocumentName(file.name);
      return validateFiles(
        mapPath.length - 1 < 0 ? null : mapPath[mapPath.length - 1].id,
        file.name
      )
        .then((response) => {
          if (response?.data.length === 0) {
            return { file, readyToUpload: true };
          } else {
            setOpenModal(true);
            setFilesToUpload([file]);
            return { file: null, readyToUpload: false };
          }
        })
        .catch((err) => {
          console.log("ERROR", err);
          return { file: null, readyToUpload: false };
        });
    });

    Promise.all(validateFilePromises).then((results) => {
      const filesReadyToUpload = results
        .filter((result) => result.readyToUpload)
        .map((result) => result.file);
      if (filesReadyToUpload.length > 0) {
        confirmActionAndUpload("", filesReadyToUpload);
      }
    });
  };

  const onClickReplace = () => {
    if (filesToUpload.length > 0) {
      confirmActionAndUpload("replace", filesToUpload);
    }
  };

  const onClickKeepBoth = () => {
    if (filesToUpload.length > 0) {
      confirmActionAndUpload("", filesToUpload);
    }
  };

  const confirmActionAndUpload = (actionSelected, filesToUpload) => {
    filesToUpload.forEach((file) => {
      setUploaded(true);
      setMessageText("Uploading document");
      uploadFileManual(
        file,
        true,
        upDocType.value ? upDocType.value.value : null,
        mapPath.length - 1 < 0 ? null : mapPath[mapPath.length - 1].id,
        true,
        actionSelected,
        (event) => {
          //setLoading8(true);
        }
      )
        .then((response) => {
          setUploaded(false);
          notify(file.name + " u" + "ploaded", "success");
          setCloseUploadFiles(true);
          setTotalFiles([]);
        })
        .catch((err) => {
          setUploaded(false);
          toast.error(err?.response?.data?.error?.messages[0].message);
        })
        .finally(() => {
          setMessageText("Getting Explorer Data");
        });
    });
    setUpDocType({});
    setOpenModal(false);
    setDocumentName("");
    setFilesToUpload([]);
  };

  const clickItem = (item, event) => {
    if (event.ctrlKey || event.metaKey) {
      if (!selectedFiles.includes(item.fkArchive.id)) {
        setSelectedFiles((selectedFiles) => [
          ...selectedFiles,
          item.fkArchive.id,
        ]);
      } else {
        setSelectedFiles(
          selectedFiles.filter((id) => id !== item.fkArchive.id)
        );
      }
    } else {
      setSelectedFiles([]);
      setItem(item);
    }
  };

  const openMenuItem = () => {};
  const setTrash = (opt) => {
    console.log("SET MODE", opt);
    setIsTrash(opt);
  };

  /**DOC-1441 confirm dialog for the trash bin */
  const emptyTrashBin = () => {
    Swal.fire({
      title:
        "Are you sure you want to permanently erase the items in the Trash? You can't undo this action",
      showCancelButton: true,
      confirmButtonText: "Empty",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(postEmptyTrashBin(navigate));
      }
    });
  };
  const resetDone = () => {
    setResetPager(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DocPlace Explorer</title>
        </MetaTags>
        <Container fluid>
          <Title title={"Explorer"} viewActive={false} />
          <ToastContainer autoClose={3000} />
          <LoadingComponent
            show={
              loading ||
              loading2 ||
              loading3 ||
              loading4 ||
              loading5 ||
              loading6 ||
              loading7 ||
              loading8 ||
              loadingEmptyTrash ||
              uploaded === true
            }
            text={messageText}
          />
          <SearchDocPlace
            original={false}
            callSearch={searchFile.bind(this)}
            clear={refresh}
          />

          {!totalFiles.length > 0 ? (
            <NavDocPlace
              showTree={showTreeFN.bind(this)}
              refresh={refresh.bind(this)}
              upLevel={upLevel.bind(this)}
              clickHomeMap={homeTree.bind(this)}
              clickMap={clickMap.bind(this)}
              callBackOptionMenuItemsOpen={openMenuItem.bind(this)}
              selectedFiles={selectedFiles}
              mapPath={mapPath}
              docTypeList={doctypes}
              clearTrash={emptyTrashBin}
            />
          ) : (
            <>
              <TitleDocType> Document Type </TitleDocType>
              <SelectDropdown
                id={"period"}
                field={upDocType}
                arrayList={listUpDocType}
                setField={setUpDocType}
                label="Document Type"
              />
              <p className={classes.helperText}>
                Select a DocType if you want to index your document(s). If you
                do not select a DocType, the documents(s) will be uploaded
                without an index and no attributes will be extracted from the
                content.
              </p>
              <Button
                onClick={saveUpload.bind(this)}
                className={classes.btnSave}
                startIcon={<UploadIcon />}
                variant="contained"
                color="success"
              >
                UPLOAD DOCUMENTS
              </Button>
              <Button
                onClick={closeUpload.bind(this)}
                className={classes.closeUpload}
                variant="outlined"
                startIcon={<CancelPresentationIcon />}
              >
                Close Upload
              </Button>
            </>
          )}

          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Grid container direction="row" alignItems="start" spacing={2}>
                <Grid item xs={2}>
                  <img
                    alt={"document"}
                    src={document}
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    A file with this name already exists, so we couldn't upload{" "}
                    <strong>{documentName}</strong>. Add it as a new version of
                    the existing file or keep them both.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Button
                      onClick={onClickReplace}
                      className={classes.ButtonModal}
                      variant="outlined"
                    >
                      Replace
                    </Button>
                    <Button
                      onClick={onClickKeepBoth}
                      className={classes.ButtonModal}
                      variant="outlined"
                    >
                      Keep both
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <FileDocPlace
            newFolder={newFolder.bind(this)}
            clickTree={clickTree.bind(this)}
            backTree={backTree.bind(this)}
            clickFile={clickFile.bind(this)}
            addComment={addComment.bind(this)}
            updateFile={updateFile.bind(this)}
            moveFile={moveFile.bind(this)}
            reprocessFile={reprocessFile}
            recoverFile={recoverFile.bind(this)}
            deleteFile={deleteFile.bind(this)}
            shareFile={shareFile.bind(this)}
            renameFile={renameFile.bind(this)}
            openFolder={openFolder.bind(this)}
            openFile={openFile.bind(this)}
            nextPage={nextPageFN.bind(this)}
            clickItem={clickItem.bind(this)}
            closeUp={closeUploadFiles}
            page={page}
            reset={resetPager}
            showTree={showTree}
            resetDone={resetDone.bind(this)}
            users={users}
            treePrevius={mapPath ? mapPath : []}
            tree={responseTree ? responseTree : []}
            docTypes={responseDocType1}
            treeActive={tree}
            file={detailFolder.data ? detailFolder.data : []}
            total={detailFolder.header ? detailFolder.header : 0}
            isTrash={isTrash}
            setTrash={setTrash.bind(this)}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ExplorerDocPlace;
