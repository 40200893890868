import {call, put, takeEvery} from "redux-saga/effects";
import {
  getRequiredSignatureDocumentError,
  getRequiredSignatureDocumentSuccess,
  getValidateUserSignatureError,
  getValidateUserSignatureSuccess,
} from "./actions";
import {
  REQUIRED_SIGNATURE_DOCUMENTS,
  REQUIRED_SIGNATURE_DOCUMENTS_CLEAR_DATA,
  VALIDATE_USER_SIGNATURE,
  VALIDATE_USER_SIGNATURE_CLEAR_DATA,
} from "./actionTypes";
import {getRequiredSignatureAPI, getValidateUserSignatureAPI} from "../../helpers/call_api";

// Validates if the user is registered in eSignature
function* getValidateUserSignature() {
  try {
    const response = yield call(getValidateUserSignatureAPI);
    yield put(getValidateUserSignatureSuccess(response));
  } catch (error) {
    yield put(getValidateUserSignatureError(error));
  }
}

function* validateUserSignatureClearData() {
  try {
    yield put(validateUserSignatureClearData(true))
  } catch (error) {
    yield put(getValidateUserSignatureError(error))
  }
}
// ****---END---****

// List required signatures for documents
function* getRequiredSignatureDocument() {
  try {
    const response = yield call(getRequiredSignatureAPI);
    yield put(getRequiredSignatureDocumentSuccess(response));
  } catch (error) {
    yield put(getRequiredSignatureDocumentError(error));
  }
}

function* requiredSignatureDocumentClearData() {
  try {
    yield put(requiredSignatureDocumentClearData(true))
  } catch (error) {
    yield put(getRequiredSignatureDocumentError(error))
  }
}
// ****---END---****

function* authSaga() {
  yield takeEvery(VALIDATE_USER_SIGNATURE, getValidateUserSignature);
  yield takeEvery(VALIDATE_USER_SIGNATURE_CLEAR_DATA, validateUserSignatureClearData);
  yield takeEvery(REQUIRED_SIGNATURE_DOCUMENTS, getRequiredSignatureDocument);
  yield takeEvery(REQUIRED_SIGNATURE_DOCUMENTS_CLEAR_DATA, requiredSignatureDocumentClearData);
}

export default authSaga;