import { makeStyles} from '@material-ui/core/styles';
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
    buttons: {
        width: '100%',
        display: 'inline-block',
        marginTop: '20px',
        height: '40px',
        color: '#560e1a'
    }
}));

export const WrapperSignature = styled.div`
  width: 100%;
  height: calc(100vh - 300px);
  overflow-y: auto;
  border-radius: 4px;
  margin-top: 20px;
  display: inline-block;
`;


  



