import React, { useEffect } from "react";
import {
  File,
  FileNew,
  TitleDocument,
  useStyles,
  Wrapper,
  WrapperFiles,
} from "./style";
import TreeItems from "./tree";
import Button from "@mui/material/Button";
import OptionMenuDocPlace from "./optionsMenu";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getDocumentPermissions } from "../../../../store/document/actions";
import {
  postDocDownload,
  postFolderDownloadZip,
} from "../../../../store/explorer/actions";
import iconPlusSquare from "../../../../assets/plus-square.png";
import iconFolder from "../../../../assets/yellow.svg";
import reviewal from "../../../../assets/blue.svg";

import fileExcel from "../../../../assets/file-excel.png";
import filePdf from "../../../../assets/file-pdf.png";
import fileDoc from "../../../../assets/file-doc.png";
import fileWord from "../../../../assets/file-word.png";
import fileImage from "../../../../assets/file-jpg.png";
import fileOther from "../../../../assets/file-other.png";
import fileZip from "../../../../assets/file-zip.png";

import Drawer from "@material-ui/core/Drawer";
import { MoveDocPlace } from "./move";
import { ShareDocPlace } from "./share";
import { RenameDocPlace } from "./raname";
import { ReprocessDocPlace } from "./reprocess";
import { RecoverDocPlace } from "./recover";

import { NewFolderDocPlace } from "./newFolder";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import FileUploadDocPlace from "../fileUpload/fileUpload";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../../../components/loading";
import folderGray from "../../../../assets/folder_gray.svg"; //DOC-1808 add folder gray
import TablePagination from "@mui/material/TablePagination";

let deleteWord = "";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
var folders = [];
var documents = [];
var allDocuments = [];
var firstSelected = "";
var lastSelected = "";

export function FileDocPlace(props, prev) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [item, setItem] = React.useState(0);
  const [showOption, setShowOption] = React.useState(false);
  const [showMove, setShowMove] = React.useState(false);
  const [showShare, setShowShare] = React.useState(false);
  const [showRename, setShowRename] = React.useState(false);
  const [showReprocess, setShowReprocess] = React.useState(false);
  const [showRecover, setShowRecover] = React.useState(false);

  const [showNewFolder, setShowNewFolder] = React.useState(false);
  const [totalFiles, setTotalFiles] = React.useState([]);
  const [updateAllFiles, setUpdateAllFiles] = React.useState(false);
  const [closeUploadFiles, setCloseUploadFiles] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [permissions, setPermissions] = React.useState(false);
  const [isTrash, setIsTrash] = React.useState(false);

  const { responseDocDownload, loadingDocDownload } = useSelector((state) => ({
    responseDocDownload: state.Explorer.responseDocDownload,
    loadingDocDownload: state.Explorer.loadingDocDownload,
  }));
  const { responseDocumentPermissions, loadingDocumentPermissions } =
    useSelector((state) => ({
      responseDocumentPermissions:
        state.DetailFolder.responseDocumentPermissions,
      loadingDocumentPermissions: state.DetailFolder.loadingDocumentPermissions,
    }));

  const { responseFolderDownload, loadingFolderDownload } = useSelector(
    (state) => ({
      responseFolderDownload: state.Explorer.responseFolderDownload,
      loadingFolderDownload: state.Explorer.loadingFolderDownload,
    })
  );

  // c

  const [objs, setObjs] = React.useState([]);
  useEffect(() => {
    setCloseUploadFiles(props.closeUp);
    if (props.closeUp) {
      setTotalFiles([]);
    }
  }, [props.closeUp]);

  useEffect(() => {
    setIsTrash(props.isTrash);
  }, [props.isTrash]);

  useEffect(() => {
    setObjs(
      []
        .concat(props.file)
        .sort((a, b) => a.fkArchive.name.localeCompare(b.fkArchive.name))
    );
    // props.file.sort((a, b) => a.fkArchive.name.localeCompare(b.fkArchive.name));
  }, [props.file]);

  useEffect(() => {
    if (props.reset == true) {
      setPage(0);
      setRowsPerPage(50);
      props.resetDone();
    }
  }, [props.reset]);

  useEffect(() => {
    if (responseDocDownload) {
      const blob = new Blob([responseDocDownload], {
        type: "application/octetstream",
      });
      const link = document.createElement("a");
      // create a blobURI pointing to our Blob
      const lastIndex = item?.fkArchive?.name?.lastIndexOf(".");
      const nameSplit = item?.fkArchive
        ? item?.fkArchive?.name?.slice(0, lastIndex)
        : "";
      link.href = URL.createObjectURL(blob);
      link.download = item.fkArchive
        ? item.fkArchive?.originalExtension
          ? nameSplit + item.fkArchive.originalExtension
          : item.fkArchive?.name
        : "";
      // some browser needs the anchor to be in the doc
      if (link.download) {
        document.body.append(link);
        link.click();
      }
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }
  }, [responseDocDownload]);

  useEffect(() => {
    const downloadFolder = () => {
      if (responseFolderDownload) {
        const name = item?.fileReferences?.originalFileName
          ? item?.fileReferences?.originalFileName
          : item?.fkArchive?.name;
        const blob = new Blob([responseFolderDownload], {
          type: "application/zip",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${name}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);
      }
    };
    downloadFolder();
  }, [responseFolderDownload]);

  useEffect(() => {
    if (responseDocumentPermissions) {
      setPermissions(responseDocumentPermissions);
    }
  }, [responseDocumentPermissions]);

  const updateFile = (files) => {
    if (files.length > 0) {
      props.updateFile(files);
    }
    setCloseUploadFiles(false);
    setTotalFiles(files);
  };

  const clickItem = (item, event) => {
    dispatch(getDocumentPermissions(item.fkArchive.id, navigate));
    setTimeout(() => {
      setShowOption(true);
    }, 200);
    setItem(item);
    setSelectedFiles([]);
    props.clickItem(item, event);
  };

  const doubleClickItemC = (item, evt) => {
    if (isTrash == false) {
      setIsTrash(item == "Trash");
      props.setTrash(item == "Trash");
    }
    if (isTrash == false) {
      setTimeout(() => {
        setShowOption(false);
      }, 200);
      optionMenu("open");
    }
  };

  const showFolder = (item) => {
    setShowNewFolder(true);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowOption(open);
  };

  const closeModal = () => {
    setShowMove(false);
    setShowShare(false);
    setShowRename(false);
    setShowNewFolder(false);
    setShowReprocess(false);
    setShowRecover(false);
  };

  const optionMenu = (opt) => {
    let type = item.folder ? "Folder" : item.fkArchive.type;

    switch (opt) {
      case "open": {
        if (type === "Folder") {
          let send = {
            label: item.fkArchive.name,
            parent: null,
            value: item.fkArchive.id,
          };
          callItemTree(send);
          setShowOption(false);
        } else {
          props.openFile(item);
          setShowOption(false);
        }
        return null;
      }
      case "download": {
        let type = item.fkArchive?.originalExtension
          .toUpperCase()
          .replace(".", "");

        dispatch(
          postDocDownload(
            {
              archiveId: item.fkArchive.id,
              fileName: item.fileReferences.originalFileName
                ? item.fileReferences.originalFileName
                : item.fkArchive.name,
              type: type,
            },
            navigate
          )
        );
        setShowOption(false);
        return null;
      }
      case "downloadZip": {
        let folderName = item.fileReferences.originalFileName
          ? item.fileReferences.originalFileName
          : item.fkArchive.name;
        let obj = [item.fkArchive.id];
        dispatch(postFolderDownloadZip(folderName, obj));
        setShowOption(false);
        return null;
      }
      case "move": {
        setShowMove(true);
        setShowOption(false);
        return null;
      }
      case "rename": {
        setShowRename(true);
        setShowOption(false);
        return null;
      }
      case "delete": {
        deleteItem();
        setShowOption(false);
        return null;
      }
      case "share": {
        setShowShare(true);
        setShowOption(false);
        return null;
      }
      case "reprocess": {
        setShowReprocess(true);
        setShowOption(false);
        return null;
      }
      case "recover": {
        setShowRecover(true);
        setShowOption(false);
        return null;
      }
      case "fileLocation": {
        if (item.fkArchive.parentArchiveId) {
          const itemToNavigate = {
            label: item.fkArchive.name,
            value: item.fkArchive.id,
          };
          backTree(itemToNavigate);
        }
        setShowOption(false);
        return null;
      }
      default: {
        return null;
      }
    }
  };
  const deleteItem = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="deleteConfirm custom-ui">
            <h1 style={{ margin: "-43px 0px 0px 0px" }}>Are you sure?</h1>
            <p>
              Write the text "DELETE" in the following box and confirm the
              deletion of the item. The item will be moved to the trash bin and
              remain there for 30 days before being permanently deleted
            </p>

            <TextField
              style={{
                width: "100%",
                backgroundColor: "#fff",
                marginBottom: "26px",
              }}
              id="delete"
              variant="outlined"
              onChange={(e) => (deleteWord = e.target.value)}
              label="Write DELETE"
            />

            <Button className={classes.btnCancel} autoFocus onClick={onClose}>
              NO
            </Button>

            <Button
              className={classes.btnDelete}
              autoFocus
              onClick={() => {
                if (deleteWord === "DELETE") {
                  deleteWord = "";
                  callDelete();
                  onClose();
                } else {
                  toast.error("Wrong Key!");
                }
              }}
            >
              Yes, Delete it!
            </Button>
          </div>
        );
      },
    });
  };

  const callDelete = () => {
    props.deleteFile({ deleteFiles: true, item: item });
  };

  const setNewTree = (obj) => {
    if (obj === null || obj === undefined) return;
    for (const [k, v] of Object.entries(obj)) {
      obj["hola"] = obj["name"];
      setNewTree(v);
    }
  };

  const callMove = (moveFile) => {
    setShowMove(false);
    props.moveFile();
  };

  const callShare = (shareFiles) => {
    setShowShare(false);
    props.shareFile({ shareFiles: shareFiles, item: item });
  };

  const callRename = (renameFiles) => {
    setShowRename(false);
    props.renameFile();
  };

  const callReprocess = (reprocessFiles) => {
    setShowReprocess(false);
    props.reprocessFile(reprocessFiles);
  };
  const callRecover = (recoverFiles) => {
    setShowRecover(false);
    props.recoverFile();
  };
  const callNewFolder = (name) => {
    setShowNewFolder(false);
    props.newFolder({ name: name, item: item });
  };

  const callUpdateContent = (content) => {
    setShowRename(false);
    props.updateFile({ updateFile: content, item: item });
  };

  const callAddComment = (comment) => {
    setShowRename(false);
    setShowOption(false);
    props.addComment({ comment: comment, item: item });
  };

  const callItemTree = (item) => {
    props.clickTree(item);
  };

  const backTree = (item) => {
    props.backTree(item);
  };
  const setFileType = (file) => {
    switch (file.fkArchive?.originalExtension?.toString().toLowerCase()) {
      case ".pdf":
        return filePdf;
      case ".doc":
      case ".docx":
        return fileWord;
      case ".jpg":
      case ".png":
      case ".jpeg":
        return fileImage;
      case ".xls":
      case ".csv":
      case ".xlsx":
        return fileExcel;
      case ".zip":
        return fileZip;
      case ".txt":
        return fileDoc;
      default:
        return fileOther;
    }
  };

  const handleChangePage = (event, newPage) => {
    //let newPage = props.page;
    //newPage = objs.length <= 1 ? 1 : newPage + 1;

    setPage(newPage);
    props.nextPage(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    props.nextPage(0, parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Wrapper>
      <LoadingComponent
        show={loadingDocDownload}
        text={"Downloading Document..."}
      />
      <LoadingComponent
        show={loadingFolderDownload}
        text={"Downloading Document..."}
      />
      <Grid container spacing={2}>
        {props.showTree && (
          <Grid item xs={4} md={3}>
            <TreeItems
              click={callItemTree.bind(this)}
              backTree={backTree.bind(this)}
              treePrevius={props.treePrevius}
              tree={props.tree}
              key="fileExplorer"
            />
          </Grid>
        )}
        <Grid item xs={props.showTree ? 8 : 12} md={props.showTree ? 9 : 12}>
          <WrapperFiles id="scrollableDiv">
            {props.total.totalRecords > 50 && (
              <TablePagination
                component="div"
                count={props.total.totalRecords}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
            <div className={classes.float_center}>
              <FileUploadDocPlace
                closeUp={closeUploadFiles}
                uploadF={updateFile.bind(this)}
                updateAllFiles={updateAllFiles}
              />

              <FileNew onClick={showFolder.bind(this, item)}>
                <img
                  src={iconPlusSquare}
                  className={classes.imgNewFolder}
                  alt=""
                />
                <TitleDocument>Create New Folder</TitleDocument>
              </FileNew>

              {objs.map((item, idx) => {
                let type = item.folder
                  ? "Folder"
                  : item.fkArchive
                  ? item.fkArchive.type
                  : "Folder";

                if (type === "Folder") {
                  folders.push(item.fkArchive.id);
                  return (
                    <File
                      onClick={clickItem.bind(this, item)}
                      onDoubleClick={() =>
                        doubleClickItemC(item.fkArchive.name)
                      }
                      className={
                        selectedFiles.includes(item.fkArchive.id) &&
                        classes.selectedFile
                      }
                      key={idx}
                    >
                      {item.fkArchive?.name === "Reviewal" ||
                      item.fkArchive?.name === "In Progress" ? (
                        <img
                          src={reviewal}
                          className={classes.imgFolder}
                          alt=""
                        />
                      ) : item.fkArchive?.name === "Trash" ? (
                        <img
                          src={folderGray}
                          className={classes.imgFolderGray}
                          alt=""
                        />
                      ) : (
                        <img
                          src={iconFolder}
                          className={classes.imgFolder}
                          alt=""
                        />
                      )}
                      <TitleDocument> {item.fkArchive?.name}</TitleDocument>
                    </File>
                  );
                } else {
                  return null;
                }
              })}
              {objs.map((item, idx) => {
                let type = item.folder
                  ? "Folder"
                  : item.fkArchive
                  ? item.fkArchive.type
                  : "Folder";

                if (!item.folder && item.fkArchive.id && type !== "Folder") {
                  documents.push(item.fkArchive.id);
                  return (
                    <File
                      onClick={clickItem.bind(this, item)}
                      onDoubleClick={doubleClickItemC.bind()}
                      className={
                        selectedFiles.includes(item.fkArchive.id) &&
                        classes.selectedFile
                      }
                      key={idx}
                    >
                      <img
                        src={setFileType(item)}
                        className={classes.imgDocument}
                        alt=""
                      />
                      <HtmlTooltip
                        style={{ padding: "0 !important" }}
                        title={
                          <div style={{ padding: "0 !important" }}>
                            <div
                              className={classes.titleTooltip}
                              color="inherit"
                            >
                              {item.fkArchive.name
                                ? item.fkArchive?.name
                                : "none"}
                            </div>
                          </div>
                        }
                      >
                        <TitleDocument classes={classes.titleShow}>
                          {item.fkArchive?.name}
                        </TitleDocument>
                      </HtmlTooltip>
                    </File>
                  );
                } else {
                  return null;
                }
              })}
            </div>
            {props.total.totalRecords > 50 && (
              <TablePagination
                component="div"
                count={props.total.totalRecords}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </WrapperFiles>
        </Grid>
      </Grid>
      <Drawer anchor="bottom" open={showOption} onClose={toggleDrawer(false)}>
        <div onClick={toggleDrawer(false)} className={classes.closeOpt}>
          CLOSE
        </div>
        <OptionMenuDocPlace
          addComment={callAddComment.bind(this)}
          updateFile={callUpdateContent.bind(this)}
          click={optionMenu.bind(this)}
          item={item}
          isTrash={isTrash}
          permissions={permissions}
          hideFileLocationOption
        />
      </Drawer>
      <NewFolderDocPlace
        newFolder={callNewFolder.bind(this)}
        close={closeModal.bind(this)}
        show={showNewFolder}
        treePrevius={props.treePrevius}
      />

      <RenameDocPlace
        rename={callRename.bind(this)}
        itemRename={item}
        close={closeModal.bind(this)}
        show={showRename}
      />
      <ReprocessDocPlace
        reprocess={callReprocess.bind(this)}
        itemReprocess={item}
        close={closeModal.bind(this)}
        show={showReprocess}
      />
      <RecoverDocPlace
        recover={callRecover.bind(this)}
        itemRecover={item}
        close={closeModal.bind(this)}
        show={showRecover}
      />
      <ShareDocPlace
        share={callShare.bind(this)}
        itemShare={item}
        users={props.users}
        close={closeModal.bind(this)}
        show={showShare}
      />
      <MoveDocPlace
        backTree={backTree.bind(this)}
        treePrevius={props.treePrevius}
        move={callMove.bind(this)}
        tree={props.tree}
        close={closeModal.bind(this)}
        itemMove={item}
        show={showMove}
      />
    </Wrapper>
  );
}
export default FileDocPlace;
