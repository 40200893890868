import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DrawIcon from "@mui/icons-material/Draw";
import PrintIcon from "@mui/icons-material/Print";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { useStyles } from "./styles";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

export default function ButtonOptions(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                className={classes.btn}
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                size="small"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Options
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} disableRipple>
                    <li>
                        <div
                            className="optionDocument"
                            onClick={props.openOption.bind(this)}
                        >
                            <TextSnippetIcon
                                style={{ fontSize: "18px", margin: "2px 6px 0 6px" }}
                            />
                            <span
                                style={{
                                    position: "absolute",
                                    right: "40px",
                                    top: "6px",
                                }}
                            >
                                {" "}
                                Document{" "}
                            </span>
                        </div>
                    </li>
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                    <li>
                        <div className="eSignature" onClick={() => props.setOpen(true)}>
                            <DrawIcon style={{ fontSize: "18px", margin: "2px 6px 0 6px" }} />
                            <span
                                style={{
                                    position: "absolute",
                                    right: "40px",
                                    top: "6px",
                                }}
                            >
                                eSignature
                            </span>
                        </div>
                    </li>
                </MenuItem>
              {/*  <MenuItem onClick={handleClose} disableRipple>*/}
              {/*      <li>*/}
              {/*          <div className="printDocument" onClick={() => props.printOption()}>*/}
              {/*              <PrintIcon*/}
              {/*                  style={{ fontSize: "18px", margin: "2px 6px 0 6px" }}*/}
              {/*              />*/}
              {/*              <span*/}
              {/*                  style={{*/}
              {/*                      position: "absolute",*/}
              {/*                      right: "18px",*/}
              {/*                      top: "3px",*/}
              {/*                  }}*/}
              {/*              >*/}
              {/*                   Print document*/}
              {/*               </span>*/}
              {/*          </div>*/}
              {/*      </li>*/}
              {/*  </MenuItem>*/}
                {/*<Divider sx={{ my: 0.5 }} />*/}
            </StyledMenu>
        </div>
    );
}