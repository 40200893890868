import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";

import missingSignature from "../../../../assets/missingSignature.png";
import moment from "moment-timezone";
import PendingActionsSharpIcon from "@mui/icons-material/PendingActionsSharp";
import CheckIcon from "@mui/icons-material/Check";
import { LiSigner, TotalSigner, UlSigner, Wrapper } from "./style";
import CustomizedProgressBars from "../progressBar/index";
import PreviewIcon from "@mui/icons-material/Preview";

const email = JSON.parse(localStorage.getItem("docplace"))?.email;

const headColSignature = [
  {
    field: "document_name",
    flex: 1,
    headerClassName: "MuiTableHead-root",
    headerName: "Document",
  },
  {
    field: "requestDate",
    flex: 1,
    headerClassName: "MuiTableHead-root",
    headerName: "Signature request date",
    type: "date",
    valueFormatter: (params) => {
      return `${moment(params.value).format("MM/DD/YYYY")}`;
    },
  },
  {
    field: "status",
    headerName: "Required Signatures Status",
    sortable: false,
    headerClassName: "MuiTableHead-root",
    flex: 1,
    renderCell: (params) => {
      const signedSigners = params?.row?.signers.filter(
        (signer) => signer.have_signed === 1
      );
      const progress =
        (signedSigners.length / params?.row?.signers.length) * 100;
      return (
        <>
          <CustomizedProgressBars progress={progress} />
        </>
      );
    },
  },
  {
    field: "signers",
    headerName: "Signers",
    sortable: false,
    headerClassName: "MuiTableHead-root",
    flex: 1,
    renderCell: (params) => (
      <>
        <UlSigner>
          {params?.value?.map((signer) => (
            <LiSigner key={signer.id}>
              {signer.signatory_email} {signer.status === ""}{" "}
              {signer.have_signed === 1 ? (
                <CheckIcon
                  sx={{
                    fontSize: 20,
                    color: "#343fdb",
                    position: "absolute",
                    margin: "-4px 0px 0px 7px",
                  }}
                />
              ) : (
                <PendingActionsSharpIcon
                  sx={{
                    fontSize: 20,
                    color: "#3498db",
                    position: "absolute",
                    margin: "-3px 0px 0px 7px",
                  }}
                />
              )}
            </LiSigner>
          ))}
          <LiSigner>
            <TotalSigner>Total: {params.value.length}</TotalSigner>
          </LiSigner>
        </UlSigner>
      </>
    ),
  },
  {
    field: "document_id",
    headerName: "Options",
    sortable: false,
    headerClassName: "MuiTableHead-root",
    flex: 1,
    renderCell: (params) => {
      const signerFound = params?.row?.signers?.find(
        (signer) => signer.signatory_email === email
      );
      console.log("signerFound", signerFound, email);

      return (
        <>
          {signerFound.have_signed === 0 ? (
            <Button
              title="Sign"
              onClick={() =>
                (window.location.href = `/stampSignature/${params.value}`)
              }
            >
              <img
                src={missingSignature}
                alt="Sign"
                style={{ width: "28px", height: "28px", marginRight: "10px" }}
              />
              <span style={{ marginLeft: "10px" }}>Sign</span>
            </Button>
          ) : (
            <Button
              title="Sign"
              onClick={() =>
                (window.location.href = `/docViewer/${params.value}`)
              }
            >
              <PreviewIcon
                fontSize="medium"
                style={{ width: "28px", height: "28px", marginRight: "10px" }}
              />
              <span style={{ marginLeft: "10px" }}>View</span>
            </Button>
          )}
        </>
      );
    },
  },
];

export function DataGridDemo(props) {
  return (
    <Wrapper>
      <DataGrid
        rows={props.rows}
        columns={headColSignature}
        getRowClassName={() => `MuiTableCell-row`}
        initialState={{
          sorting: {
            sortModel: [{ field: "document_name", sort: "asc" }],
          },
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          },
        }}
        pageSizeOptions={[25, 50]}
      />
    </Wrapper>
  );
}

export default DataGridDemo;
