import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { TextField } from "@mui/material";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  ButtonContainer,
  ButtonContainerTools,
  Container,
  ContainerTextField,
  DivHelperTextTitle,
  useStyles,
  WrapperCheckBox,
  Title
} from "./style";
import { colors } from "../../../../components/theme";


const CheckBoxView = (props) => {
  const classes = useStyles();
  return (
    <WrapperCheckBox>
      <Container>
       
          <>
            <Title>{props.title}</Title>
            <ButtonContainer>
            {props.viewPdf ? (
              <Button
                variant="text"
                onClick={props.onChangeViewPdf}
                style={{
                  textTransform: "none",
                  margin: "-12px 0 20px 0",
                  backgroundColor: props.notIntoPdfCheckB && colors.gray3,
                }}
                startIcon={<VisibilityOffIcon />}
              >
                Hide in PDF
              </Button>
              ) : (
              <Button
                variant="text"
                onClick={props.onChangeViewPdf}
                style={{
                  textTransform: "none",
                  margin: "-12px 0 20px 0",
                  backgroundColor: props.notIntoPdfCheckB && colors.gray3,
                }}
                startIcon={<VisibilityIcon />}
              >
                Show in PDF
              </Button>
              )}

            </ButtonContainer>
            <ContainerTextField>
              <TextField
                id="helpTextTitle"
                placeholder="Click to Enter Title"
                fullWidth={true}
                value={props._title}
                variant="standard"
                onChange={props.onChangeTitle}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                  },
                }}
              />
            </ContainerTextField>
            {props.options?.map((value) => (
            <FormControlLabel
            control={
              <Checkbox
                defaultChecked={value.value === "true" || value.value === "checked" || value.value === "1"  ? true : false}
                onChange={props.onChangeCheckBox}
              />
            }
            label={value.label}
          />
              ))}

            <ContainerTextField>
              <TextField
                id="helpTestDescription"
                placeholder="Click to Enter Description"
                fullWidth={true}
                variant="standard"
                onChange={props.onChangeDescription}
                value={props._description}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "12px",
                    fontWeight: "lighter",
                  },
                }}
              />
            </ContainerTextField>
            <ButtonContainerTools>
            <Button
              onClick={props.onChangeSettings}
              style={{
                textTransform: "none",
                marginRight: "20px",
              }}
              variant={"text"}
              startIcon={<SettingsSuggestIcon />}
            >
              Settings
            </Button>
              <Button
                onClick={props.onChangeDelete}
                style={{
                  textTransform: "none",
                }}
                color="error"
                variant={"text"}
                startIcon={<DeleteForeverIcon />}
              >
                Delete
              </Button>
            </ButtonContainerTools>
          </>
      
      </Container>
    </WrapperCheckBox>
  );
};

export default CheckBoxView;
