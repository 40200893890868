import {
  REQUIRED_SIGNATURE_DOCUMENTS,
  REQUIRED_SIGNATURE_DOCUMENTS_CLEAR_DATA,
  REQUIRED_SIGNATURE_DOCUMENTS_ERROR,
  REQUIRED_SIGNATURE_DOCUMENTS_SUCCESS,
  VALIDATE_USER_SIGNATURE,
  VALIDATE_USER_SIGNATURE_CLEAR_DATA,
  VALIDATE_USER_SIGNATURE_ERROR,
  VALIDATE_USER_SIGNATURE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorValidateUserSignature: null,
  loadingValidateUserSignature: false,
  responseValidateUserSignature: null,
  loadingRequiredSignatureDocument: false,
  errorRequiredSignatureDocument: null,
  responseRequiredSignatureDocument: null,
};

const UserValidateSignature = (state = initialState, action) => {
  switch (action.type) {
// Validates if the user is registered in eSignature
    case VALIDATE_USER_SIGNATURE:
      state = {
        ...state,
        errorValidateUserSignature: null,
        loadingValidateUserSignature: true,
      }
      break;
    case VALIDATE_USER_SIGNATURE_SUCCESS:
      state = {
        ...state,
        errorValidateUserSignature: null,
        loadingValidateUserSignature: false,
        responseValidateUserSignature: action.payload,
      }
      break;
    case VALIDATE_USER_SIGNATURE_ERROR:
      state = {
        ...state,
        errorValidateUserSignature: action.payload,
        loadingValidateUserSignature: false,
        responseValidateUserSignature: null,
      }
      break;
    case VALIDATE_USER_SIGNATURE_CLEAR_DATA:
      state = {
        ...state,
        errorValidateUserSignature: null,
        loadingValidateUserSignature: false,
        responseValidateUserSignature: null,
      }
      break;
// ****---END---****
// List required signatures for documents
    case REQUIRED_SIGNATURE_DOCUMENTS:
      state = {
        ...state,
        errorRequiredSignatureDocument: null,
        loadingRequiredSignatureDocument: true,
      }
      break;
    case REQUIRED_SIGNATURE_DOCUMENTS_SUCCESS:
      state = {
        ...state,
        errorRequiredSignatureDocument: null,
        loadingRequiredSignatureDocument: false,
        responseRequiredSignatureDocument: action.payload,
      }
      break;
    case REQUIRED_SIGNATURE_DOCUMENTS_ERROR:
      state = {
        ...state,
        errorRequiredSignatureDocument: action.payload,
        loadingRequiredSignatureDocument: false,
        responseRequiredSignatureDocument: null,
      }
      break;
    case REQUIRED_SIGNATURE_DOCUMENTS_CLEAR_DATA:
      state = {
        ...state,
        errorRequiredSignatureDocument: null,
        loadingRequiredSignatureDocument: false,
        responseRequiredSignatureDocument: null,
      }
      break;
// ****---END---****
    default:
      state = { ...state }
      break;
  }
  return state
}

export default UserValidateSignature
