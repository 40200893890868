import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import TitleDocPlace from "../../components/title/title";
import SearchDocPlace from "../../components/search/search";
import GridAssignedForms from "./resouerce/grid/grid";
import LoadingComponent from "../../components/loading";
import { WrapperAssignedForms } from "./style";
import {
  getValidateUserSignature,
  validateUserSignatureClearData,
} from "../../store/eSignature/actions";
import AssignedFormsViewDocPlace from "./view";
import { getFormsRequested } from "../../store/Forms/actions";

const AssignedForms = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userSignature, setUserSignature] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [allFormsRequested, setAllFormsRequested] = useState([]);
  const [email] = useState(JSON.parse(localStorage.getItem("docplace")));
  const [documentName, setDocumentName] = useState([]);

  const { responseValidateUserSignature, loadingValidateUserSignature } =
    useSelector((state) => ({
      responseValidateUserSignature:
        state.UserValidateSignature.responseValidateUserSignature,
      loadingValidateUserSignature:
        state.UserValidateSignature.loadingValidateUserSignature,
    }));

  const { responseGetAllFormsRequested, loadingGetAllFormsRequested } =
    useSelector((state) => ({
      responseGetAllFormsRequested:
        state.AllFormsRequested.responseGetAllFormsRequested,
      loadingGetAllFormsRequested:
        state.AllFormsRequested.loadingGetAllFormsRequested,
    }));

  const findDocumentTypes = (term) => {
    setAllFormsRequested(
      term
        ? documentName.filter((doc) =>
            doc.DocumentName.toLowerCase().includes(term)
          )
        : documentName
    );
  };

  const searchFile = (filterSearch) => {
    findDocumentTypes(filterSearch);
  };

  const callModalConfirmation = () => {
    setShowConfirmation(!showConfirmation);
  };

  useEffect(() => {
    if (!responseGetAllFormsRequested) {
      dispatch(getFormsRequested({ email: email.email }));
    }
  }, []);

  useEffect(() => {
    let newObject = [];
    if (Array.isArray(responseGetAllFormsRequested)) {
      responseGetAllFormsRequested.forEach((formsRequested, i) => {
        newObject.push({ ...formsRequested, id: i });
      });
      setAllFormsRequested(newObject);
      setDocumentName(newObject);
    }
  }, [responseGetAllFormsRequested]);

  useEffect(() => {
    if (!responseValidateUserSignature) {
      dispatch(getValidateUserSignature());
    }
  }, []);

  useEffect(() => {
    if (responseValidateUserSignature) {
      setUserSignature(responseValidateUserSignature.data.isValid);
      dispatch(validateUserSignatureClearData());
    }
  }, [responseValidateUserSignature]);

  useEffect(() => {
    if (!userSignature) {
      setShowConfirmation(true);
    }
  }, [userSignature]);

  return (
    <React.Fragment>
      <AssignedFormsViewDocPlace
        show={showConfirmation}
        close={callModalConfirmation}
      />
      <Container fluid>
        <TitleDocPlace title={"Assigned forms"} viewActive={false} />
        <LoadingComponent
          show={loadingGetAllFormsRequested}
          text={"Obtaining forms..."}
        />
        <SearchDocPlace callSearch={searchFile.bind(this)} />
        <WrapperAssignedForms>
          <GridAssignedForms rows={allFormsRequested} />
        </WrapperAssignedForms>
      </Container>
    </React.Fragment>
  );
};

export default AssignedForms;
