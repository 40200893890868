import React, {useState, useEffect} from 'react'
import ShareIcon from '@mui/icons-material/Share';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import InboxIcon from '@mui/icons-material/Inbox';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Grid from "@mui/material/Grid";
function OverviewInfo(props) {
    const [detail, setDetailActive] = useState('none');
    const [show, setShow]  = useState(false);

    useEffect( () => {
        props.selectDetail(detail, show);
    }, [detail, show])
    
    function setView(value, view) {
        setDetailActive(value);
        setShow(view);
    }

    return (
            <Grid container className={`row overview-info ${show == true ? "overview-info-active" : "" }`}>
                <Grid item xs={6} md={6}>
                    {
                        props.icon === 'shared' ?
                            <ShareIcon className="overIcon"/> : props.icon === 'doctype' ?
                                <AnalyticsIcon className="overIcon"/> : props.icon === 'document' ?
                                    <InboxIcon className="overIcon"/> : props.icon === 'reviewal' ?
                                        <WarningAmberIcon className="overIcon"/> : null
                    }
                </Grid>
                <Grid item xs={6} md={6}>
                    <h4>{props.numberValue}</h4>
                    <p>{props.textValue}</p>
                </Grid>
            </Grid>

    )
}

export default OverviewInfo
