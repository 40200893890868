import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../components/theme";

export const WrapperLabeling = styled.div`
  width: 100%;
  height: calc(100vh - 151px);
  background-color: ${colors.ground};
  overflow: hidden;
`;
export const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 0px;
  margin-bottom: 10px;
`;
export const TemplateLabeling = styled.article`
  width: 200px;
  height: 100%;
  background-color: red;
  float: left;
`;

export const AttributesLabeling = styled.article`
  width: 200px;
  height: 100%;
  background-color: blue;
  float: right;
`;

export const Labeling = styled.article`
  min-width: 1000px;
  width: auto;
  height: 100%;
  background-color: #000;
  float: left;
`;

export const TitleTemplate = styled.h4`
  color: #006ac6;
  margin: 10px auto;
  text-align: center;
  font-weight: 900;
`;

export const WrapperDocumentTemplate = styled.section`
  width: 100%;
  padding: 10px 0px 10px 0px;
  cursor: pointer;
  :hover {
    background-color: #e8e6e6;
  }
`;

export const DocumentTemplate = styled.section`
  margin: 10px auto;
  text-align: center;
  width: 120px;
  height: 120px;
  background-color: #9e9e9e;
`;

export const DocumentNameTemplate = styled.div`
  color: #908888;
  text-align: center;
  font-weight: 100;
  margin: -7px 0px 10px 0;
  word-break: break-word;
  line-height: 1;
`;

export const WrapperDocumentAttribute = styled.section`
  width: 100%;
  padding: 10px 0px 10px 0px;
  cursor: pointer;
`;

export const DocumentAttributeItem = styled.div`
  width: 60%;
  margin: 0px auto;
  cursor: pointer;
  background-color: #607d8b;
  height: 25px;
  text-align: center;
  position: relative;
  padding-top: 2px;
  border-radius: 5px;
  color: #fff;
  font-weight: 100;
  :hover {
    font-weight: bold;
  }
`;

export const DocumentAttributeType = styled.section`
  width: 100%;
  padding: 10px 0px 10px 0px;
  cursor: pointer;
`;

export const DocumentAttributeLine = styled.div`
  width: 2px;
  height: 20px;
  background-color: #000;
  margin: -7px auto;
`;

export const DocumentAttributeText = styled.div`
  width: 100%;
  height: 20px;
  text-align: center;
  margin-top: 5px;
`;

export const MenuContent = styled.nav`
  width: 99%;
  height: 40px;
  background-color: ${colors.white};
  margin-top: 8px;
  display: inline-block;
  box-shadow: 0px 2px 5px #9e9e9e;
  border-radius: 5px;
`;

export const MenuContentUL = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const MenuContentLI = styled.li`
  margin: 0;
  padding: 0;
  float: left;
  color: #006ac6;
  margin: 4px 15px 0px 14px;
  cursor: pointer;
  :hover {
    font-weight: 600;
  }
`;

export const TitleMenu = styled.span`
  margin-top: 5px;
  display: inline-block;
  float: right;
`;

export const LabelingContent = styled.article`
  width: 99%;
  height: calc(100vh - 110px);
  background-color: ${colors.white};
  box-shadow: 0px 2px 5px #9e9e9e;
  margin-top: 5px;
  overflow: auto;
`;

export const Nav = styled.nav`
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
`;

export const Ul = styled.ul`
  position: relative;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 45px;
`;

export const useStyles = makeStyles((theme) => ({
  rowClear: {
    margin: 0,
    padding: 0,
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  templateLabeling: {
    backgroundColor: "#fff",
    overflowY: "auto",
    height: "100%",
    position: "relative",
    overflowX: "hidden",
  },
  attributeLabeling: {
    backgroundColor: "#fff",
    overflowY: "auto",
    height: "100%",
  },
  Labeling: {
    backgroundColor: "#fff",
  },
  newTemplate: {
    width: "100%",
    backgroundColor: "#006ac6 !important",
    margin: "15px auto !important",
    textAlign: "center",
    display: "block",
    lineHeight: "1 !important",
  },
  optionMenu: {
    float: "left",
    width: "50%",
    height: "40px",
    backgroundColor: "#607d8b",
    textAlign: "center",
    paddingTop: "9px",
    color: "#fff",
    cursor: "pointer",
    margin: "0",
  },
  optionWrapper: {
    position: "fixed",
    bottom: 0,
    width: "14%",
    overflowX: "hidden",
  },
  deleteAttribute: {
    right: "-28px",
    position: "absolute",
    color: "red",
    top: "-12px",
    cursor: "pointer",
  },
  wrapperAside: {
    height: "calc(100vh - 144px)",
    overflowY: "auto",
  },
  wrapperTools: {
    height: "auto",
    overflowY: "auto",
  },
  ltLabelUnselected: {
    marginTop: "0px",
    cursor: "pointer",
    with: "100%",
    textAlign: "left",
    textTransform: "capitalize",
    width: "100%",
    border: "none !important",
    // boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: "4px  !important",
    paddingLeft: "10px !important",
  },
  ltLabelSelected: {
    marginTop: "0px",
    cursor: "pointer",
    with: "100%",
    textAlign: "left",
    textTransform: "capitalize",
    backgroundColor: "rgba(253,255,50, 50%) !important",
    width: "100%",
    border: "none !important",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    padding: "4px  !important",
    paddingLeft: "10px !important",
  },
  ltUnSelected: {
    color: "#646464 !important",
    cursor: "pointer",
    textAlign: "left  !important",
    textTransform: "none !important",
    justifyContent: "left !important",
    width: "100%",
    paddingTop: "2px",
    fontSize: "15px",
  },
  ltSelected: {
    color: "#006ac6 !important",
    fontWeight: "bold",
    cursor: "pointer",
    width: "100%",
    textAlign: "left !important",
    textTransform: "none  !important",
    justifyContent: "left !important",
    paddingTop: "2px",
    fontSize: "15px",
  },
  textList: {
    backgroundColor: "white",
    width: "100%",
    fontSize: "14px",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    textAlign: "left",
    paddingLeft: "8px",
    cursor: "pointer",
  },
  textListItem: {
    display: "inline-block",
    color: "gray",
    marginRight: "4px",
    fontSize: "12px",
    padding: "4px",
  },
  ltInput: {
    marginTop: "0px !important",
    fontSize: "14px !important",
    padding: "4px",
    border: "1px solid lightgray",
    borderRadius: "4px",
  },
}));
