import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { colors, theme } from "../../components/theme";

export const ResendCodeWrapper = styled.section`
  padding: 0px;
  border: 2px solid rgb(0 193 212);
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const CountDown = styled.article`
  width: 100%;
  height: 50px;
  text-align: center;
`;

export const CountDownText = styled.div`
  font-size: 12px;
`;

export const CountDownNumber = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const ResendCodeTitle = styled.article`
  width: 100%;
  height: 38px;
  color: ${colors.white};
  background-color: rgb(0 193 212);
  text-align: center;
  padding-top: 14px;
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 2px 2px 0px 0px;
`;

export const ResendCodeContent = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ResendCodeContentOpt = styled.li`
  padding: 0px;
  cursor: pointer;
  height: 41px;
  padding-top: 15px;
  padding-left: 14px;
  color: #006ac6;
  background-color: #fff;
  &:hover {
    background-color: rgb(225 225 225);
  }
`;

export const LoginFormDocPlace = styled.form`
  width: 100%;
  height: 300px;
  margin-top: 0%;
  padding: 30px 12px 12px 8px;
  background-color: ${colors.white};
`;

export const WrapperFormDocPlace = styled.section`
  margin: 0px 25px 0px 25px;
`;

export const SingInDocPlace = styled.div`
  font-size: 1.3rem;
  color: ${colors.blue};
  margin: 0px 0px 27px 0;
  text-decoration: underline;
  text-align: left;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${colors.white};
  background-image: url(${theme.bg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
`;

export const WrapperLogin = styled.div`
  width: 300px;
  height: 100%;
  background-color: ${colors.white};
  float: right;
  overflow: hidden;
  display: block;
  max-width: 300px;
  box-shadow: 1px 0px 13px ${colors.gray};
`;

export const ForgetPassword = styled.div`
  height: 10px;
  color: #607d8b;
  font-size: 11px;
  margin: 12px auto;
  width: 100%;
  text-align: center;

  cursor: pointer;
`;
export const ResendEmail = styled.div`
  height: 10px;
  color: #607d8b;
  font-size: 11px;
  margin: 12px auto;
  width: 100%;
  text-align: center;

  cursor: pointer;
  color: red;
`;

export const useStyles = makeStyles((theme) => ({
  buttons: {
    width: "100%",
    display: "inline-block",
    marginTop: "20px",
    height: "40px",
    backgroundColor: "#119949 !important",
    color: "#fff !important",
  },
  imgLogo: {
    width: "100%",
    height: "150px",
    objectFit: "contain",
    margin: "50% 0px 0px 0px",
  },
}));
